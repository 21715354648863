html, body {
    min-height: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: @font-family-sans-serif;
    position: relative;
}

body.maps {
    background-image: url('/images/bg-maps.jpg');
    background-size: cover;
    background-position: center;
}

#app {
    position: absolute;
    top: @header-height;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

#app.map-anon {
    background-image: url('/images/bg-maps.jpg');
    background-size: cover;
    background-position: center;
}

.FCYOMaps {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    background: url('/images/bg-grid.png') repeat;
}
