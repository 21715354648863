.box {
    margin: 80px auto;
    padding: 2em;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

    h1 {
        margin: 0.25em 0 0.5em;
    }

    p {
        margin: 10px 0;
    }

    .form-control:focus {
        border-color: #a201ff;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(162,1,255,.6);
    }
}

#form-login {
}

#form-register {
    p {
        font-size: 18px;
    }

    .row > * {
        margin-top: 1em;
        padding: 0 2em;

        &:last-of-type {
            border-left: 1px solid #eee;
        }
    }
    .row .row > * {
        padding: 0 1em;
        margin-top: 0;
    }
}

#register-success {
    p:not(:last-of-type) {
        font-size: 18px;
        margin: 2em 0;
        text-align: left;
    }
}

.radio-box {
    display: block;
    position: relative;
    padding-left: 35px;
    margin: 15px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 1.6;

    /* Hide the browser's default radio button */
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    /* Create a custom radio button */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 50%;
        transition: 0.3s background-color ease-in-out;


        /* Create & Style the indicator (dot/circle - hidden when not checked) */
        &:after {
            content: "";
            position: absolute;
            display: block;
            visibility: hidden;
            top: 9px;
            left: 9px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: white;
            transition: 0.5s visibility cubic-bezier(.21,.8,.69,.14);
        }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: #ccc;
    }

    /* When the radio button is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: #a201ff;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .checkmark:after {
        visibility: visible;
    }
}

@media screen and (max-width: 992px) {
    #form-register {
        .row .row > * {
            border-left: 0;
        }
    }
}