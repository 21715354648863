.FCYOMaps {
    .leaflet-popup-content-wrapper {
        border-radius: 0;
    }
    
    .leaflet-control-zoom {
        border-radius: 0;
        box-shadow: 0 0 5px rgba(0,0,0, 0.4);
        
        a {
            border-radius: 0;
        }
    }
    
    .leaflet-control-attribution {
        opacity: 0.85;
    }
    
    .Marker {
        font-family: @font-family-sans-serif;
        text-align: center;
        
        address p {
            margin: 0;
            padding: 0;
        }
    }
}

.public-false .Marker h3,
.public-false .Marker address {
  color: transparent;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
}
