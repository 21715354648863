#header {
    background-color: darken(#232323, 10%);
    color: white;
    border-radius: 0;
    margin: 0;
    border: none;
    min-height: @header-height;
    max-height: @header-height;
    
    .navbar-brand {
        padding: 11px 15px;
    }
    
    .navbar-toggle {
        margin: 25px 2em 25px 0;
        border-radius: 0;
    }
    
    #navbar-map {
        margin: 0;
        
        ul.nav.navbar-nav {
            li a {
                padding: 30px 15px;
                color: @brand-warning;
                text-transform: uppercase;
                font-size: 1.4em;
                transition: color 250ms ease-in-out;

                &:hover,
                &:active {
                    color: #fff;
                }
            }

            li.map-nav {
                margin: 20px 0 20px 15px;
                background-color: @brand-warning;

                a {
                    padding: 10px 15px;
                    display: inline-block;
                    vertical-align: middle;
                    color: #000;

                    &:hover,
                    &:active {
                        color: #fff;
                    }

                    &:first-child {
                        color: #fff;
                    }

                    &.small {
                        font-size: @font-size-base;
                    }
                }
            }
        }
    }
}

@media (max-width: @screen-sm-max) {
    #header {
        #navbar-map {
            position: absolute;
            top: 84px;
            left: 0;
            width: 100%;
            background-color: rgba(0,0,0, 0.8);
            z-index: 2000;
            overflow: auto;

            ul.nav.navbar-nav {
                text-align: center;
                margin: 0;
                
                li a {
                    padding: 20px 15px;
                }
                
                li.map-nav a {
                    display: block;
                }
            }
        }
    }
}



.navbar-nav>li>.dropdown-menu{
    z-index: 999 !important;
}
.navbar-nav>li>.dropdown-menu a{
    text-align: left !important;
    color: #ffb223 !important;
    padding: 5px 15px;
}
.navbar-nav>li>.dropdown-menu a:hover{
    color: #fd4c24 !important;
}

.dropdown:hover .dropdown-menu {
    display: block;
}
#header #navbar-map ul.nav.navbar-nav li.dropdown li a {
    text-align: left !important;
    color: #ffb223 !important;
    padding: 5px 15px;
}

@media screen and (max-width: 991px) {

    .navbar-nav>li>.dropdown-menu{
        float: none;
        position: relative;
        border-radius: 0;
        display: block;
        background-color: transparent;
        margin: 0;
        padding: 0;
        border: 0;
    }

    .navbar-nav>li>.dropdown-menu a{
        text-align: right !important;
        padding: 10px 15px;
        line-height: 1;
    }

    #header #navbar-map ul.nav.navbar-nav li.dropdown li a {
        text-align: center !important;
    }
    #header #navbar-map ul.nav.navbar-nav li.dropdown li a:hover {
        background-color: transparent;
        color: #ffffff !important;
    }
}