@icon-size: 24px;

.AutoComplete {
    position: relative;
    overflow: visible;
    display: flex;

    input {
        box-sizing: border-box;
        width: 100%;
        padding: 6px;
        border: 1px solid #ccc;
        background-color: rgba(255, 255, 255, 0.65);

        &:disabled {
            cursor: not-allowed;
        }

        &:active,
        &:focus {
            outline: none;
            border: 1px solid darken(#ccc, 15%);
        }
    }

    i.fa {
        font-size: 18px;
        color: @brand-danger;
        background-color: #ccc;
        border: 1px solid #ccc;
        border-right: none;
        width: 2.5em;
        text-align: center;
        vertical-align: middle;
        line-height: 32px;
    }

    .suggest-list {
        margin: -1px 0 0;
        padding: 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #fff;
        border: 1px solid #999;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
        z-index: 100;

        li {
            list-style: none;
            margin: 0;
            padding: 4px 8px;
            cursor: pointer;

            &.active {
                background-color: lightyellow;
            }

            &.disabled {
                color: #999;
            }
        }
    }
}
