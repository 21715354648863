.ListView {
    padding: 3em 2em 2em;
    overflow-y: auto;
    
    table.table {
        
        thead th {
            cursor: pointer;
            
            i.fa {
                float: left;
                margin-right: 0.5em;
                line-height: 20px;
            }
        }
        
        a {
            display: block;
        }
        
        tfoot {
            .placeholder {
                color: @gray-light;
                text-align: center;
                margin: 0;
                padding: 1em;
            }
            
            nav {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;

                .pagination {
                    margin: 0;
                }

                > * {
                    transition: transform 500ms ease-in, opacity 500ms ease-in;
                    opacity: 1;
                    transform: scale(1);

                    &.out {
                        opacity: 0;
                        transform: scale(0);
                    }
                }
            }
        }
    }
}

tr.public-false td {
  color: transparent;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
}