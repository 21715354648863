.FilterForm {
    position: relative;
    min-width: @filter-form-width;
    max-width: @filter-form-width;
    height: 100%;
    overflow: visible;
    box-sizing: border-box;
    z-index: 11;

    form {
        z-index: 100;
        width: 100%;
        height: 100%;
        background: #fff url("/images/bg-funky.jpg") repeat;
        padding: 2em;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        transform: translate3d(0, 0, 0);
        transition: all 500ms ease-in-out;

        &.open {
            transform: translate3d(-250px, 0, 0);
        }

        > .btn-group {
            margin-bottom: 2em;

            .btn {
                line-height: 2;
            }
        }

        > .form-group {
            margin-bottom: 2em;

            > a {
                float: right;
            }

            label {
                font-size: 16px;
                display: inline-block;
                margin: 0;
            }
        }
    }
    .results-count {
        margin-top: 2em;
        color: #8200cd;
        font-size: 18px;
        text-align: center;
        span:first-of-type {
            font-weight: 700;
        }
    }

    .download-btn {
        padding: 5px 15px;
        color: #fff;
        background-color: #a201ff;
        font-size: 16px;
        text-transform: uppercase;
        display: block;
        max-width: 180px;
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        position: relative;
        margin: 15px auto;
        &:before {
            content: "";
            position: absolute;
            border: #a201ff solid 2px;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition-duration: 0.3s;
            transition-property: top, right, bottom, left;
        }
        &:hover {
            &:before {
                top: -5px;
                right: -5px;
                bottom: -5px;
                left: -5px;
            }
        }
    }
}

.drawer {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100%;
    overflow: hidden;

    .layer {
        position: absolute;
        height: 100%;
        width: 100%;
        padding-left: 50px;
        overflow: hidden;
        background: #fff;
        transform: translate3d(-260px, 0, 0) scale3d(0.7, 0.7, 0.7);
        transition: transform 500ms ease-in-out;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;

        &.open {
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        }

        h3 {
            text-align: center;
            padding-top: 1.5em;
            padding-bottom: 1em;
            min-height: 3.5em;
            position: relative;
            font-weight: bold;
            border-bottom: 1px solid #ddd;
            margin: 0;

            i.fa {
                position: absolute;
                z-index: -1;
                top: 50%;
                left: 50%;
                margin-top: -0.5em;
                margin-left: -0.5em;
                font-size: 2.5em;
                opacity: 0.05;
            }
        }

        .close-drawer {
            position: absolute;
            top: 12px;
            right: 12px;
            padding: 0;
            color: @brand-danger;
            font-size: @font-size-small;
            border: none;
            background: transparent;
        }

        ul.checkbox-list {
            width: 100%;
            overflow: auto;
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                margin: 0;
                border-top: 1px solid #ddd;

                &:first-child {
                    border: none;
                }

                &.heading {
                    background-color: @gray-lighter;
                    font-weight: 500;
                    padding: 0.5em 0.5em 0.5em 1em;
                    text-transform: uppercase;
                }

                label {
                    font-weight: 400;
                    padding: 0.5em;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    margin: 0;

                    input {
                        margin: 0 0.5em 0;
                    }
                }
            }
        }
    }
}