@keyframes navigate-enter {
    // Start full size in background
    0% {
        transform: translateX(0) scale(1);
        z-index: 1;
        opacity: 0;
    }
    // Push backward, staying slightly larger than entering element
    25% {
        transform: translateX(0) scale(0.9);
        opacity: 1;
    }
    // Slide to left and sync scale with entering element
    50% {
        transform: translateX(-55%) scale(0.85);
        z-index: 1;
    }
    // Once slide is finished and elements don't overlap, seamlessly move to to foreground
    51% {
        z-index: 2;
    }
    // Slide back to center and scale slightly smaller than element behind
    75% {
        transform: translateX(0) scale(0.8);
        z-index: 2;
    }
    // Move back into starting position
    100% {
        transform: translateX(0) scale(1);
        z-index: 2;
    }
}

@keyframes navigate-leave {
    // Start full size in foreground
    0% {
        transform: translateX(0) scale(1);
        z-index: 2;
    }
    // Push backward
    25% {
        transform: translateX(0) scale(0.8);
    }
    // Slide to right and sync scale with leaving element
    50% {
        transform: translateX(55%) scale(0.85);
        z-index: 2;
    }
    // Once slide is finished and elements don't overlap, seamlessly move to background 
    51% {
        z-index: 1;
    }
    // Slide back to center and scale slightly larger than element in front
    75% {
        transform: translateX(0) scale(0.9);
        z-index: 1;
        opacity: 1;
    }
    // Move back to starting position and fade out
    100% {
        transform: translateX(0) scale(1);
        z-index: 1;
        opacity: 0;
    }
}

@keyframes navigate-wide {
    0% {
        transform: translateY(102%);
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


/*
 * View change transitions
 */
.navigate-enter {
    animation: 1.5s ease-in-out forwards navigate-enter;
}
.navigate-leave {
    animation: 1.5s ease-in-out forwards navigate-leave;
}

.navigate-wide-enter {
    &.ProfileView {
        animation: 750ms ease-in-out forwards navigate-wide;
    }
}
.navigate-wide-leave {
    &.ProfileView {
        animation: 750ms ease-in-out backwards reverse navigate-wide;
    }
}


/*
 * FilterBadge transitions
 */
.fade-enter {
    opacity: 0.01;
    transition: opacity 250ms ease-in-out;
}

.fade-enter.fade-enter-active {
    opacity: 1;
}

.fade-leave {
    opacity: 1;
    transition: opacity 250ms ease-in-out;
}

.fade-leave.fade-leave-active {
    opacity: 0;
}
