.FilterBox {
    position: absolute;
    top: 0;
    left: 0;
    width: ~"calc(100% - @{filter-form-width})";
    padding: 2em 0;
    border-bottom: 2px solid @brand-danger;
    box-sizing: border-box;
    background-color: rgba(0,0,0, 0.8);
    z-index: 10;
    transform: translateY(-100%);
    transition: transform 250ms ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: #fff;
    
    &.open {
        transform: translateY(0);
    }
    
    > a {
        position: absolute;
        bottom: -2em;
        left: 50%;
        height: 2em;
        margin-left: -50px;
        background-color: @brand-danger;
        color: #fff;
        padding: 0 1em;
        line-height: 2em;
        width: 100px;
        text-align: center;
        font-size: 12px;
        
        &:before {
            position: absolute;
            top: 0;
            left: -2em;
            content: '';
            width: 0;
            height: 0;
            border-top: 2em solid @brand-danger;
            border-left: 2em solid transparent;
        }
        
        &:after {
            position: absolute;
            top: 0;
            right: -2em;
            content: '';
            width: 0;
            height: 0;
            border-top: 2em solid @brand-danger;
            border-right: 2em solid transparent;
        }
    }
    
    .col {
        width: 25%;
        border-left: 1px solid rgba(255,255,255, 0.15);
        padding: 0 1.5em;
        
        &:first-of-type {
            border: none;
        }
        
        h3 {
            margin-top: 0;
            font-size: 18px;
        }
        
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            .placeholder {
                color: rgba(255,255,255, 0.4);
                font-style: italic;
            }
        }
    }
}

.FilterItem {
    display: flex;
    align-items: baseline;
    
    .value {
        width: 100%;
    }
    
    .fa,
    .close {
        min-width: 1.2em;
    }
    
    .fa {
        color: @brand-danger;
        margin-right: 0.5em;
    }

    .close {
        color: rgba(255,255,255, 0.8);
        font-size: inherit;
        font-family: Arial, sans-serif;
        text-align: right;
    }
}
