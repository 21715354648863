/* required styles */

.leaflet-map-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-pane,
.leaflet-tile-container,
.leaflet-overlay-pane,
.leaflet-shadow-pane,
.leaflet-marker-pane,
.leaflet-popup-pane,
.leaflet-overlay-pane svg,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
	position: absolute;
	left: 0;
	top: 0;
	}
.leaflet-container {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	-webkit-user-drag: none;
	}
.leaflet-marker-icon,
.leaflet-marker-shadow {
	display: block;
	}
/* map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container img {
	max-width: none !important;
	}
/* stupid Android 2 doesn't understand "max-width: none" properly */
.leaflet-container img.leaflet-image-layer {
	max-width: 15000px !important;
	}
.leaflet-tile {
	filter: inherit;
	visibility: hidden;
	}
.leaflet-tile-loaded {
	visibility: inherit;
	}
.leaflet-zoom-box {
	width: 0;
	height: 0;
	}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
	-moz-user-select: none;
	}

.leaflet-tile-pane    { z-index: 2; }
.leaflet-objects-pane { z-index: 3; }
.leaflet-overlay-pane { z-index: 4; }
.leaflet-shadow-pane  { z-index: 5; }
.leaflet-marker-pane  { z-index: 6; }
.leaflet-popup-pane   { z-index: 7; }

.leaflet-vml-shape {
	width: 1px;
	height: 1px;
	}
.lvml {
	behavior: url(#default#VML);
	display: inline-block;
	position: absolute;
	}


/* control positioning */

.leaflet-control {
	position: relative;
	z-index: 7;
	pointer-events: auto;
	}
.leaflet-top,
.leaflet-bottom {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	}
.leaflet-top {
	top: 0;
	}
.leaflet-right {
	right: 0;
	}
.leaflet-bottom {
	bottom: 0;
	}
.leaflet-left {
	left: 0;
	}
.leaflet-control {
	float: left;
	clear: both;
	}
.leaflet-right .leaflet-control {
	float: right;
	}
.leaflet-top .leaflet-control {
	margin-top: 10px;
	}
.leaflet-bottom .leaflet-control {
	margin-bottom: 10px;
	}
.leaflet-left .leaflet-control {
	margin-left: 10px;
	}
.leaflet-right .leaflet-control {
	margin-right: 10px;
	}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile,
.leaflet-fade-anim .leaflet-popup {
	opacity: 0;
	-webkit-transition: opacity 0.2s linear;
	   -moz-transition: opacity 0.2s linear;
	     -o-transition: opacity 0.2s linear;
	        transition: opacity 0.2s linear;
	}
.leaflet-fade-anim .leaflet-tile-loaded,
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
	opacity: 1;
	}

.leaflet-zoom-anim .leaflet-zoom-animated {
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
	   -moz-transition:    -moz-transform 0.25s cubic-bezier(0,0,0.25,1);
	     -o-transition:      -o-transform 0.25s cubic-bezier(0,0,0.25,1);
	        transition:         transform 0.25s cubic-bezier(0,0,0.25,1);
	}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile,
.leaflet-touching .leaflet-zoom-animated {
	-webkit-transition: none;
	   -moz-transition: none;
	     -o-transition: none;
	        transition: none;
	}

.leaflet-zoom-anim .leaflet-zoom-hide {
	visibility: hidden;
	}


/* cursors */

.leaflet-clickable {
	cursor: pointer;
	}
.leaflet-container {
	cursor: -webkit-grab;
	cursor:    -moz-grab;
	}
.leaflet-popup-pane,
.leaflet-control {
	cursor: auto;
	}
.leaflet-dragging .leaflet-container,
.leaflet-dragging .leaflet-clickable {
	cursor: move;
	cursor: -webkit-grabbing;
	cursor:    -moz-grabbing;
	}


/* visual tweaks */

.leaflet-container {
	background: #ddd;
	outline: 0;
	}
.leaflet-container a {
	color: #0078A8;
	}
.leaflet-container a.leaflet-active {
	outline: 2px solid orange;
	}
.leaflet-zoom-box {
	border: 2px dotted #38f;
	background: rgba(255,255,255,0.5);
	}


/* general typography */
.leaflet-container {
	font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
	}


/* general toolbar styles */

.leaflet-bar {
	box-shadow: 0 1px 5px rgba(0,0,0,0.65);
	border-radius: 4px;
	}
.leaflet-bar a,
.leaflet-bar a:hover {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	width: 26px;
	height: 26px;
	line-height: 26px;
	display: block;
	text-align: center;
	text-decoration: none;
	color: black;
	}
.leaflet-bar a,
.leaflet-control-layers-toggle {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	display: block;
	}
.leaflet-bar a:hover {
	background-color: #f4f4f4;
	}
.leaflet-bar a:first-child {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	}
.leaflet-bar a:last-child {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom: none;
	}
.leaflet-bar a.leaflet-disabled {
	cursor: default;
	background-color: #f4f4f4;
	color: #bbb;
	}

.leaflet-touch .leaflet-bar a {
	width: 30px;
	height: 30px;
	line-height: 30px;
	}


/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
	font: bold 18px 'Lucida Console', Monaco, monospace;
	text-indent: 1px;
	}
.leaflet-control-zoom-out {
	font-size: 20px;
	}

.leaflet-touch .leaflet-control-zoom-in {
	font-size: 22px;
	}
.leaflet-touch .leaflet-control-zoom-out {
	font-size: 24px;
	}


/* layers control */

.leaflet-control-layers {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
	background: #fff;
	border-radius: 5px;
	}
.leaflet-control-layers-toggle {
	background-image: url(images/layers.png);
	width: 36px;
	height: 36px;
	}
.leaflet-retina .leaflet-control-layers-toggle {
	background-image: url(images/layers-2x.png);
	background-size: 26px 26px;
	}
.leaflet-touch .leaflet-control-layers-toggle {
	width: 44px;
	height: 44px;
	}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
	display: none;
	}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
	display: block;
	position: relative;
	}
.leaflet-control-layers-expanded {
	padding: 6px 10px 6px 6px;
	color: #333;
	background: #fff;
	}
.leaflet-control-layers-selector {
	margin-top: 2px;
	position: relative;
	top: 1px;
	}
.leaflet-control-layers label {
	display: block;
	}
.leaflet-control-layers-separator {
	height: 0;
	border-top: 1px solid #ddd;
	margin: 5px -10px 5px -6px;
	}


/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
	background: #fff;
	background: rgba(255, 255, 255, 0.7);
	margin: 0;
	}
.leaflet-control-attribution,
.leaflet-control-scale-line {
	padding: 0 5px;
	color: #333;
	}
.leaflet-control-attribution a {
	text-decoration: none;
	}
.leaflet-control-attribution a:hover {
	text-decoration: underline;
	}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
	font-size: 11px;
	}
.leaflet-left .leaflet-control-scale {
	margin-left: 5px;
	}
.leaflet-bottom .leaflet-control-scale {
	margin-bottom: 5px;
	}
.leaflet-control-scale-line {
	border: 2px solid #777;
	border-top: none;
	line-height: 1.1;
	padding: 2px 5px 1px;
	font-size: 11px;
	white-space: nowrap;
	overflow: hidden;
	-moz-box-sizing: content-box;
	     box-sizing: content-box;

	background: #fff;
	background: rgba(255, 255, 255, 0.5);
	}
.leaflet-control-scale-line:not(:first-child) {
	border-top: 2px solid #777;
	border-bottom: none;
	margin-top: -2px;
	}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
	border-bottom: 2px solid #777;
	}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	box-shadow: none;
	}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: 2px solid rgba(0,0,0,0.2);
	background-clip: padding-box;
	}


/* popup */

.leaflet-popup {
	position: absolute;
	text-align: center;
	}
.leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: left;
	border-radius: 12px;
	}
.leaflet-popup-content {
	margin: 13px 19px;
	line-height: 1.4;
	}
.leaflet-popup-content p {
	margin: 18px 0;
	}
.leaflet-popup-tip-container {
	margin: 0 auto;
	width: 40px;
	height: 20px;
	position: relative;
	overflow: hidden;
	}
.leaflet-popup-tip {
	width: 17px;
	height: 17px;
	padding: 1px;

	margin: -10px auto 0;

	-webkit-transform: rotate(45deg);
	   -moz-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	     -o-transform: rotate(45deg);
	        transform: rotate(45deg);
	}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	background: white;

	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	}
.leaflet-container a.leaflet-popup-close-button {
	position: absolute;
	top: 0;
	right: 0;
	padding: 4px 4px 0 0;
	text-align: center;
	width: 18px;
	height: 14px;
	font: 16px/14px Tahoma, Verdana, sans-serif;
	color: #c3c3c3;
	text-decoration: none;
	font-weight: bold;
	background: transparent;
	}
.leaflet-container a.leaflet-popup-close-button:hover {
	color: #999;
	}
.leaflet-popup-scrolled {
	overflow: auto;
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	}

.leaflet-oldie .leaflet-popup-content-wrapper {
	zoom: 1;
	}
.leaflet-oldie .leaflet-popup-tip {
	width: 24px;
	margin: 0 auto;

	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
	}
.leaflet-oldie .leaflet-popup-tip-container {
	margin-top: -1px;
	}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
	border: 1px solid #999;
	}


/* div icon */

.leaflet-div-icon {
	background: #fff;
	border: 1px solid #666;
	}

@font-face {
  font-family: 'missiongothic';
  src: url('../../fonts/missiongothic/mission_gothic_bold-webfont.eot');
  src: url('../../fonts/missiongothic/mission_gothic_bold-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/missiongothic/mission_gothic_bold-webfont.woff2') format('woff2'), url('../../fonts/missiongothic/mission_gothic_bold-webfont.woff') format('woff'), url('../../fonts/missiongothic/mission_gothic_bold-webfont.ttf') format('truetype'), url('../../fonts/missiongothic/mission_gothic_bold-webfont.svg#mission_gothicbold') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'missiongothic';
  src: url('../../fonts/missiongothic/mission_gothic_light-webfont.eot');
  src: url('../../fonts/missiongothic/mission_gothic_light-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/missiongothic/mission_gothic_light-webfont.woff2') format('woff2'), url('../../fonts/missiongothic/mission_gothic_light-webfont.woff') format('woff'), url('../../fonts/missiongothic/mission_gothic_light-webfont.ttf') format('truetype'), url('../../fonts/missiongothic/mission_gothic_light-webfont.svg#mission_gothiclight') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'missiongothic';
  src: url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.eot');
  src: url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.woff2') format('woff2'), url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.woff') format('woff'), url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.ttf') format('truetype'), url('../../fonts/missiongothic/mission_gothic_regular_italic-webfont.svg#mission_gothicregular_italic') format('svg');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'missiongothic';
  src: url('../../fonts/missiongothic/mission_gothic_regular-webfont.eot');
  src: url('../../fonts/missiongothic/mission_gothic_regular-webfont.eot?#iefix') format('embedded-opentype'), url('../../fonts/missiongothic/mission_gothic_regular-webfont.woff2') format('woff2'), url('../../fonts/missiongothic/mission_gothic_regular-webfont.woff') format('woff'), url('../../fonts/missiongothic/mission_gothic_regular-webfont.ttf') format('truetype'), url('../../fonts/missiongothic/mission_gothic_regular-webfont.svg#mission_gothicregular') format('svg');
  font-weight: 500;
  font-style: normal;
}
/**
 * Profile variables
 */
@keyframes navigate-enter {
  0% {
    transform: translateX(0) scale(1);
    z-index: 1;
    opacity: 0;
  }
  25% {
    transform: translateX(0) scale(0.9);
    opacity: 1;
  }
  50% {
    transform: translateX(-55%) scale(0.85);
    z-index: 1;
  }
  51% {
    z-index: 2;
  }
  75% {
    transform: translateX(0) scale(0.8);
    z-index: 2;
  }
  100% {
    transform: translateX(0) scale(1);
    z-index: 2;
  }
}
@keyframes navigate-leave {
  0% {
    transform: translateX(0) scale(1);
    z-index: 2;
  }
  25% {
    transform: translateX(0) scale(0.8);
  }
  50% {
    transform: translateX(55%) scale(0.85);
    z-index: 2;
  }
  51% {
    z-index: 1;
  }
  75% {
    transform: translateX(0) scale(0.9);
    z-index: 1;
    opacity: 1;
  }
  100% {
    transform: translateX(0) scale(1);
    z-index: 1;
    opacity: 0;
  }
}
@keyframes navigate-wide {
  0% {
    transform: translateY(102%);
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
/*
 * View change transitions
 */
.navigate-enter {
  animation: 1.5s ease-in-out forwards navigate-enter;
}
.navigate-leave {
  animation: 1.5s ease-in-out forwards navigate-leave;
}
.navigate-wide-enter.ProfileView {
  animation: 750ms ease-in-out forwards navigate-wide;
}
.navigate-wide-leave.ProfileView {
  animation: 750ms ease-in-out backwards reverse navigate-wide;
}
/*
 * FilterBadge transitions
 */
.fade-enter {
  opacity: 0.01;
  transition: opacity 250ms ease-in-out;
}
.fade-enter.fade-enter-active {
  opacity: 1;
}
.fade-leave {
  opacity: 1;
  transition: opacity 250ms ease-in-out;
}
.fade-leave.fade-leave-active {
  opacity: 0;
}
#header {
  background-color: #0a0a0a;
  color: white;
  border-radius: 0;
  margin: 0;
  border: none;
  min-height: 128px;
  max-height: 128px;
}
#header .navbar-brand {
  padding: 11px 15px;
}
#header .navbar-toggle {
  margin: 25px 2em 25px 0;
  border-radius: 0;
}
#header #navbar-map {
  margin: 0;
}
#header #navbar-map ul.nav.navbar-nav li a {
  padding: 30px 15px;
  color: #ffb223;
  text-transform: uppercase;
  font-size: 1.4em;
  transition: color 250ms ease-in-out;
}
#header #navbar-map ul.nav.navbar-nav li a:hover,
#header #navbar-map ul.nav.navbar-nav li a:active {
  color: #fff;
}
#header #navbar-map ul.nav.navbar-nav li.map-nav {
  margin: 20px 0 20px 15px;
  background-color: #ffb223;
}
#header #navbar-map ul.nav.navbar-nav li.map-nav a {
  padding: 10px 15px;
  display: inline-block;
  vertical-align: middle;
  color: #000;
}
#header #navbar-map ul.nav.navbar-nav li.map-nav a:hover,
#header #navbar-map ul.nav.navbar-nav li.map-nav a:active {
  color: #fff;
}
#header #navbar-map ul.nav.navbar-nav li.map-nav a:first-child {
  color: #fff;
}
#header #navbar-map ul.nav.navbar-nav li.map-nav a.small {
  font-size: 14px;
}
@media (max-width: 991px) {
  #header #navbar-map {
    position: absolute;
    top: 84px;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2000;
    overflow: auto;
  }
  #header #navbar-map ul.nav.navbar-nav {
    text-align: center;
    margin: 0;
  }
  #header #navbar-map ul.nav.navbar-nav li a {
    padding: 20px 15px;
  }
  #header #navbar-map ul.nav.navbar-nav li.map-nav a {
    display: block;
  }
}
.navbar-nav > li > .dropdown-menu {
  z-index: 999 !important;
}
.navbar-nav > li > .dropdown-menu a {
  text-align: left !important;
  color: #ffb223 !important;
  padding: 5px 15px;
}
.navbar-nav > li > .dropdown-menu a:hover {
  color: #fd4c24 !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
#header #navbar-map ul.nav.navbar-nav li.dropdown li a {
  text-align: left !important;
  color: #ffb223 !important;
  padding: 5px 15px;
}
@media screen and (max-width: 991px) {
  .navbar-nav > li > .dropdown-menu {
    float: none;
    position: relative;
    border-radius: 0;
    display: block;
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0;
  }
  .navbar-nav > li > .dropdown-menu a {
    text-align: right !important;
    padding: 10px 15px;
    line-height: 1;
  }
  #header #navbar-map ul.nav.navbar-nav li.dropdown li a {
    text-align: center !important;
  }
  #header #navbar-map ul.nav.navbar-nav li.dropdown li a:hover {
    background-color: transparent;
    color: #ffffff !important;
  }
}
.box {
  margin: 80px auto;
  padding: 2em;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}
.box h1 {
  margin: 0.25em 0 0.5em;
}
.box p {
  margin: 10px 0;
}
.box .form-control:focus {
  border-color: #a201ff;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(162, 1, 255, 0.6);
}
#form-register p {
  font-size: 18px;
}
#form-register .row > * {
  margin-top: 1em;
  padding: 0 2em;
}
#form-register .row > *:last-of-type {
  border-left: 1px solid #eee;
}
#form-register .row .row > * {
  padding: 0 1em;
  margin-top: 0;
}
#register-success p:not(:last-of-type) {
  font-size: 18px;
  margin: 2em 0;
  text-align: left;
}
.radio-box {
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 15px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.6;
  /* Hide the browser's default radio button */
  /* Create a custom radio button */
  /* On mouse-over, add a grey background color */
  /* When the radio button is checked, add a blue background */
  /* Show the indicator (dot/circle) when checked */
}
.radio-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-box .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
  transition: 0.3s background-color ease-in-out;
  /* Create & Style the indicator (dot/circle - hidden when not checked) */
}
.radio-box .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  visibility: hidden;
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  transition: 0.5s visibility cubic-bezier(0.21, 0.8, 0.69, 0.14);
}
.radio-box:hover input ~ .checkmark {
  background-color: #ccc;
}
.radio-box input:checked ~ .checkmark {
  background-color: #a201ff;
}
.radio-box input:checked ~ .checkmark:after {
  visibility: visible;
}
@media screen and (max-width: 992px) {
  #form-register .row .row > * {
    border-left: 0;
  }
}
html,
body {
  min-height: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "missiongothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
}
body.maps {
  background-image: url('/images/bg-maps.jpg');
  background-size: cover;
  background-position: center;
}
#app {
  position: absolute;
  top: 128px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
#app.map-anon {
  background-image: url('/images/bg-maps.jpg');
  background-size: cover;
  background-position: center;
}
.FCYOMaps {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  background: url('/images/bg-grid.png') repeat;
}
.View {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.View > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.View .leaflet-container {
  width: 100%;
  height: 100%;
}
.ProfileView {
  position: fixed;
  top: 128px;
  z-index: 1001;
}
.FilterBox {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 300px);
  padding: 2em 0;
  border-bottom: 2px solid #fd4c24;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  transform: translateY(-100%);
  transition: transform 250ms ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  color: #fff;
}
.FilterBox.open {
  transform: translateY(0);
}
.FilterBox > a {
  position: absolute;
  bottom: -2em;
  left: 50%;
  height: 2em;
  margin-left: -50px;
  background-color: #fd4c24;
  color: #fff;
  padding: 0 1em;
  line-height: 2em;
  width: 100px;
  text-align: center;
  font-size: 12px;
}
.FilterBox > a:before {
  position: absolute;
  top: 0;
  left: -2em;
  content: '';
  width: 0;
  height: 0;
  border-top: 2em solid #fd4c24;
  border-left: 2em solid transparent;
}
.FilterBox > a:after {
  position: absolute;
  top: 0;
  right: -2em;
  content: '';
  width: 0;
  height: 0;
  border-top: 2em solid #fd4c24;
  border-right: 2em solid transparent;
}
.FilterBox .col {
  width: 25%;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0 1.5em;
}
.FilterBox .col:first-of-type {
  border: none;
}
.FilterBox .col h3 {
  margin-top: 0;
  font-size: 18px;
}
.FilterBox .col ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.FilterBox .col ul .placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-style: italic;
}
.FilterItem {
  display: flex;
  align-items: baseline;
}
.FilterItem .value {
  width: 100%;
}
.FilterItem .fa,
.FilterItem .close {
  min-width: 1.2em;
}
.FilterItem .fa {
  color: #fd4c24;
  margin-right: 0.5em;
}
.FilterItem .close {
  color: rgba(255, 255, 255, 0.8);
  font-size: inherit;
  font-family: Arial, sans-serif;
  text-align: right;
}
.FilterForm {
  position: relative;
  min-width: 300px;
  max-width: 300px;
  height: 100%;
  overflow: visible;
  box-sizing: border-box;
  z-index: 11;
}
.FilterForm form {
  z-index: 100;
  width: 100%;
  height: 100%;
  background: #fff url("/images/bg-funky.jpg") repeat;
  padding: 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transform: translate3d(0, 0, 0);
  transition: all 500ms ease-in-out;
}
.FilterForm form.open {
  transform: translate3d(-250px, 0, 0);
}
.FilterForm form > .btn-group {
  margin-bottom: 2em;
}
.FilterForm form > .btn-group .btn {
  line-height: 2;
}
.FilterForm form > .form-group {
  margin-bottom: 2em;
}
.FilterForm form > .form-group > a {
  float: right;
}
.FilterForm form > .form-group label {
  font-size: 16px;
  display: inline-block;
  margin: 0;
}
.FilterForm .results-count {
  margin-top: 2em;
  color: #8200cd;
  font-size: 18px;
  text-align: center;
}
.FilterForm .results-count span:first-of-type {
  font-weight: 700;
}
.FilterForm .download-btn {
  padding: 5px 15px;
  color: #fff;
  background-color: #a201ff;
  font-size: 16px;
  text-transform: uppercase;
  display: block;
  max-width: 180px;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  margin: 15px auto;
}
.FilterForm .download-btn:before {
  content: "";
  position: absolute;
  border: #a201ff solid 2px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-duration: 0.3s;
  transition-property: top, right, bottom, left;
}
.FilterForm .download-btn:hover:before {
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.drawer {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  overflow: hidden;
}
.drawer .layer {
  position: absolute;
  height: 100%;
  width: 100%;
  padding-left: 50px;
  overflow: hidden;
  background: #fff;
  transform: translate3d(-260px, 0, 0) scale3d(0.7, 0.7, 0.7);
  transition: transform 500ms ease-in-out;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
}
.drawer .layer.open {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}
.drawer .layer h3 {
  text-align: center;
  padding-top: 1.5em;
  padding-bottom: 1em;
  min-height: 3.5em;
  position: relative;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
.drawer .layer h3 i.fa {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  font-size: 2.5em;
  opacity: 0.05;
}
.drawer .layer .close-drawer {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
  color: #fd4c24;
  font-size: 12px;
  border: none;
  background: transparent;
}
.drawer .layer ul.checkbox-list {
  width: 100%;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}
.drawer .layer ul.checkbox-list li {
  margin: 0;
  border-top: 1px solid #ddd;
}
.drawer .layer ul.checkbox-list li:first-child {
  border: none;
}
.drawer .layer ul.checkbox-list li.heading {
  background-color: #eeeeee;
  font-weight: 500;
  padding: 0.5em 0.5em 0.5em 1em;
  text-transform: uppercase;
}
.drawer .layer ul.checkbox-list li label {
  font-weight: 400;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin: 0;
}
.drawer .layer ul.checkbox-list li label input {
  margin: 0 0.5em 0;
}
.AutoComplete {
  position: relative;
  overflow: visible;
  display: flex;
}
.AutoComplete input {
  box-sizing: border-box;
  width: 100%;
  padding: 6px;
  border: 1px solid #ccc;
  background-color: rgba(255, 255, 255, 0.65);
}
.AutoComplete input:disabled {
  cursor: not-allowed;
}
.AutoComplete input:active,
.AutoComplete input:focus {
  outline: none;
  border: 1px solid #a6a6a6;
}
.AutoComplete i.fa {
  font-size: 18px;
  color: #fd4c24;
  background-color: #ccc;
  border: 1px solid #ccc;
  border-right: none;
  width: 2.5em;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
}
.AutoComplete .suggest-list {
  margin: -1px 0 0;
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #999;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
.AutoComplete .suggest-list li {
  list-style: none;
  margin: 0;
  padding: 4px 8px;
  cursor: pointer;
}
.AutoComplete .suggest-list li.active {
  background-color: lightyellow;
}
.AutoComplete .suggest-list li.disabled {
  color: #999;
}
.FCYOMaps .leaflet-popup-content-wrapper {
  border-radius: 0;
}
.FCYOMaps .leaflet-control-zoom {
  border-radius: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.FCYOMaps .leaflet-control-zoom a {
  border-radius: 0;
}
.FCYOMaps .leaflet-control-attribution {
  opacity: 0.85;
}
.FCYOMaps .Marker {
  font-family: "missiongothic", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
}
.FCYOMaps .Marker address p {
  margin: 0;
  padding: 0;
}
.public-false .Marker h3,
.public-false .Marker address {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.ListView {
  padding: 3em 2em 2em;
  overflow-y: auto;
}
.ListView table.table thead th {
  cursor: pointer;
}
.ListView table.table thead th i.fa {
  float: left;
  margin-right: 0.5em;
  line-height: 20px;
}
.ListView table.table a {
  display: block;
}
.ListView table.table tfoot .placeholder {
  color: #777777;
  text-align: center;
  margin: 0;
  padding: 1em;
}
.ListView table.table tfoot nav {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.ListView table.table tfoot nav .pagination {
  margin: 0;
}
.ListView table.table tfoot nav > * {
  transition: transform 500ms ease-in, opacity 500ms ease-in;
  opacity: 1;
  transform: scale(1);
}
.ListView table.table tfoot nav > *.out {
  opacity: 0;
  transform: scale(0);
}
tr.public-false td {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.ProfileView {
  height: calc(100% - 128px);
}
.ProfileView > nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fd4c24;
  border-top: 1px solid #d32802;
  border-bottom: 1px solid #d32802;
}
.ProfileView > nav button {
  margin-left: 1em;
}
.ProfileView > nav .nav li {
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #d32802;
}
.ProfileView > nav .nav li:first-child {
  border-left: 1px solid #d32802;
}
.ProfileView > nav .nav li a {
  color: #fff;
  padding: 0.75em 1.5em;
  overflow: hidden;
}
.ProfileView > nav .nav li a:hover {
  background-color: #fe8a70;
}
.ProfileView > nav .nav li a > * {
  vertical-align: middle;
}
.ProfileView > nav .nav li a .fa {
  font-size: 2em;
  margin-right: 0.5em;
}
.ProfileView > nav .nav li:after {
  background-color: #d32802;
  visibility: hidden;
  position: absolute;
  content: "";
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-100%) rotate(45deg);
  transition: opacity 250ms ease-in, transform 250ms ease-in;
  margin: 0.5px 0 0;
  width: 0.8em;
  height: 0.8em;
  border: none;
  border-bottom: 1px solid #d32802;
  border-right: 1px solid #d32802;
  z-index: 2;
}
.ProfileView > nav .nav li.active a,
.ProfileView > nav .nav li.active a:active {
  background-color: #d32802;
}
.ProfileView > nav .nav li.active:after {
  visibility: visible;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.ProfileView > nav .nav li.active .fa {
  animation: 500ms bounce-icon;
  animation-timing-function: ease-in-out;
}
.OrganizationProfile {
  position: absolute;
  top: 51px;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("/images/bg-funky.jpg");
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.OrganizationProfile > aside {
  min-width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 2em;
  color: #000;
  text-align: center;
}
.OrganizationProfile > aside .logo {
  height: 80px;
}
.OrganizationProfile > aside .logo img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}
.OrganizationProfile > aside .name h1 {
  font-size: 40px;
  padding: 0;
  margin: 0;
  color: #fff;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}
.OrganizationProfile > aside .name a {
  font-size: 1.5em;
  color: #000;
  margin-top: 0.5em;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
}
.OrganizationProfile > aside .name a:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: #fd4c24;
  height: 4px;
  transition-property: right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.OrganizationProfile > aside .name a:hover {
  color: #fff;
}
.OrganizationProfile > aside .name a:hover:before {
  right: 0;
}
.OrganizationProfile > aside .donate-button {
  padding: 8px 15px;
  color: #fff;
  background-color: #fd4c24;
  font-size: 28px;
  text-transform: uppercase;
  display: block;
  min-width: 250px;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.OrganizationProfile > aside .donate-button:before {
  content: "";
  position: absolute;
  border: #fd4c24 solid 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-duration: 0.3s;
  transition-property: top, right, bottom, left;
}
.OrganizationProfile > aside .donate-button:hover:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}
.OrganizationProfile > aside .map {
  height: 200px;
  position: relative;
}
.OrganizationProfile > aside .map img {
  max-width: 100%;
  max-height: 100%;
  border: 4px solid rgba(255, 255, 255, 0.2);
}
.OrganizationProfile > aside .map .marker {
  display: block;
  background-image: url("/images/marker-standard.png");
  background-size: contain;
  width: 33px;
  height: 46px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -12.5px;
  z-index: 2;
}
.ProfileContent {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.ProfileContent > section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  padding: 2em;
  font-size: 16px;
}
.ProfileContent > section.split {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  padding: 0;
}
.ProfileContent > section.split .content {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 2em;
}
.ProfileContent h1.heading {
  border-bottom: 1px solid #ddd;
  margin-top: 1.5em;
}
.ProfileContent h1.heading:first-child {
  margin-top: 0;
}
.ProfileContainer nav {
  display: block;
  background-color: #fd4c24;
  border-top: 1px solid #d32802;
  border-bottom: 1px solid #d32802;
}
.ProfileContainer nav .nav li {
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #d32802;
}
.ProfileContainer nav .nav li:first-child {
  border-left: 1px solid #d32802;
}
.ProfileContainer nav .nav li a {
  color: #fff;
  padding: 0.75em 1.5em;
  overflow: hidden;
}
.ProfileContainer nav .nav li a:hover {
  background-color: #fe8a70;
}
.ProfileContainer nav .nav li a > * {
  vertical-align: middle;
}
.ProfileContainer nav .nav li a .fa {
  font-size: 2em;
  margin-right: 0.5em;
}
.ProfileContainer nav .nav li:after {
  background-color: #d32802;
  visibility: hidden;
  position: absolute;
  content: "";
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(-150%) rotate(45deg);
  transition: opacity 250ms ease-in, transform 250ms ease-in;
  margin: 0.5px 0 0;
  width: 0.8em;
  height: 0.8em;
  border: none;
  border-bottom: 1px solid #d32802;
  border-right: 1px solid #d32802;
  z-index: 2;
}
.ProfileContainer nav .nav li.active a,
.ProfileContainer nav .nav li.active a:active {
  background-color: #d32802;
}
.ProfileContainer nav .nav li.active:after {
  visibility: visible;
  transform: translateX(-50%) translateY(-55%) rotate(45deg);
}
.ProfileContainer nav .nav li.active .fa {
  animation: 750ms bounce-icon;
  animation-timing-function: ease-in-out;
}
.InfoBox {
  min-width: 250px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: stretch;
  background-color: #eeeeee;
}
.InfoBox .InfoBoxItem {
  text-align: center;
  color: #222;
}
.InfoBox .InfoBoxItem .fa {
  display: block;
  color: #fd4c24;
  font-size: 2em;
}
.InfoBox .InfoBoxItem h5 {
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 5px;
}
.InfoBox .InfoBoxItem p {
  margin: 0;
  font-size: 18px;
}
#programs .Program,
#victories .Victory {
  border-left: 4px solid #fd4c24;
  background-color: #f3f3f3;
  padding: 1.5em;
  margin-bottom: 2em;
}
#programs .Program:last-child,
#victories .Victory:last-child {
  margin-bottom: 0;
}
#programs .Program ul,
#victories .Victory ul {
  list-style: none;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  margin: 0;
}
#programs .Program ul li,
#victories .Victory ul li {
  padding: 0.25em 0;
  display: flex;
  align-items: baseline;
}
#programs .Program ul li.heading,
#victories .Victory ul li.heading {
  text-transform: uppercase;
  font-weight: 500;
}
#programs .Program ul .placeholder,
#victories .Victory ul .placeholder {
  color: #bbb;
}
#programs .Program ul .fa,
#victories .Victory ul .fa {
  color: #fd4c24;
  margin-right: 1em;
}
#programs .Program .description {
  margin: 2em 0;
}
#programs .Program ul {
  width: calc(100% / 3);
  padding-right: 0.5em;
}
#victories .Victory {
  overflow: auto;
}
#victories .Victory > .heading {
  margin-bottom: 1em;
}
#victories .Victory > .scope {
  text-transform: uppercase;
}
#victories .Victory > .description {
  margin: 1.5em 0 0;
}
#victories .Victory > ul {
  width: 250px;
  float: right;
  background-color: #fff;
  padding: 0.5em 1em;
  margin: 0 0.5em 1em 1em;
}
.ProfileContent #contact {
  text-align: center;
  padding: 2em;
}
.ProfileContent #contact hr {
  margin: 3em 0;
}
.ProfileContent #contact .title {
  text-transform: uppercase;
  font-weight: normal;
}
.ProfileContent #contact .social {
  word-spacing: 2em;
}
.ProfileContent #contact .social a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  color: #fff;
  margin-right: 20px;
}
.ProfileContent #contact .social a:last-of-type {
  margin: 0;
}
.ProfileContent #contact .social a.twitter {
  background-color: #55acee;
}
.ProfileContent #contact .social a.facebook {
  background-color: #3b5998;
}
.ProfileContent #contact .social a.youtube {
  background-color: #cd201f;
}
.ProfileContent #contact .social a.instagram {
  background-color: #000;
}
.ProfileContent #contact .social a .fa {
  font-size: 3em;
}
.ProfileContent #contact address p {
  margin: 0;
  padding: 0;
}
.navigate-profile-enter {
  z-index: 1;
}
.navigate-profile-leave {
  transform: translateY(0);
  transition: transform 750ms ease-in-out;
  z-index: 2;
}
.navigate-profile-leave.navigate-profile-leave-active {
  transform: translateY(100%);
}
@keyframes bounce-icon {
  0% {
    transform: translateY(0);
  }
  49% {
    transform: translateY(50px);
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: translateY(-50px);
  }
  51% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
.OrganizationProfile.public-false .name h1,
.OrganizationProfile.public-false .content div,
.OrganizationProfile.public-false .content ul li,
.OrganizationProfile.public-false .ProfileContent #contact .contact h2,
.OrganizationProfile.public-false .ProfileContent #contact .contact h4,
.OrganizationProfile.public-false .ProfileContent #contact .contact a,
.OrganizationProfile.public-false .InfoBox .InfoBoxItem p {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.nav > li > form > a:focus,
.nav > li > form > a:hover {
  text-decoration: none;
  background-color: transparent;
}
.navbar-nav > li > form > a {
  line-height: 20px;
  display: block;
}
@media (max-width: 768px) {
  .nav > li > form > a {
    padding: 10px 15px;
  }
}
#header .nav.navbar-right li form a:hover,
#header .nav.navbar-right li form a:active,
#header .nav.navbar-right li form a:focus {
  background-color: transparent;
}
#header .nav.navbar-right form a {
  color: #ffb223;
  text-transform: uppercase;
  font-size: 1.6em;
  text-align: right;
  transition: color .3s;
}
#header .nav.navbar-right li form a:hover {
  color: white;
}
#header .nav.navbar-right li.active form a {
  color: white;
}
