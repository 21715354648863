.ProfileView {
    height: ~"calc(100% - @{header-height})";

    > nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: @profile-nav-color;
        border-top: 1px solid darken(@profile-nav-color, 15%);
        border-bottom: 1px solid darken(@profile-nav-color, 15%);

        button {
            margin-left: 1em;
        }

        .nav {
            li {
                display: inline-block;
                vertical-align: top;
                border-right: 1px solid darken(@profile-nav-color, 15%);

                &:first-child {
                    border-left: 1px solid darken(@profile-nav-color, 15%);
                }

                a {
                    color: #fff;
                    padding: 0.75em 1.5em;
                    overflow: hidden;

                    &:hover {
                        background-color: lighten(@profile-nav-color, 15%);
                    }

                    > * {
                        vertical-align: middle;
                    }

                    .fa {
                        font-size: 2em;
                        margin-right: 0.5em;
                    }
                }

                &:after {
                    background-color: darken(@profile-nav-color, 15%);
                    visibility: hidden;
                    position: absolute;
                    content: "";
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-100%) rotate(45deg);
                    transition: opacity 250ms ease-in, transform 250ms ease-in;
                    margin: 0.5px 0 0;
                    width: 0.8em;
                    height: 0.8em;
                    border: none;
                    border-bottom: 1px solid darken(@profile-nav-color, 15%);
                    border-right: 1px solid darken(@profile-nav-color, 15%);
                    z-index: 2;
                }
            }

            li.active {
                a,
                a:active {
                    background-color: darken(@profile-nav-color, 15%);
                }

                &:after {
                    visibility: visible;
                    transform: translateX(-50%) translateY(-50%) rotate(45deg);
                }

                .fa {
                    animation: 500ms bounce-icon;
                    animation-timing-function: ease-in-out;
                }
            }
        }
    }
}

.OrganizationProfile {
    position: absolute;
    top: 51px;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/images/bg-funky.jpg");
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    > aside {
        min-width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 0 2em;
        color: #000;
        text-align: center;

        .logo {
            height: 80px;

            img {
                display: block;
                max-height: 100%;
                max-width: 100%;
            }
        }

        .name {
            h1 {
                font-size: 40px;
                padding: 0;
                margin: 0;
                color: #fff;
                text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
            }

            a {
                font-size: 1.5em;
                color: #000;
                margin-top: 0.5em;

                display: inline-block;
                vertical-align: middle;
                transform: perspective(1px) translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                position: relative;
                overflow: hidden;
                transition: color 0.3s ease-in-out;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    left: 0;
                    right: 100%;
                    bottom: 0;
                    background: #fd4c24;
                    height: 4px;
                    transition-property: right;
                    transition-duration: 0.3s;
                    transition-timing-function: ease-out;
                }

                &:hover {
                    color: #fff;
                    &:before {
                        right: 0;
                    }
                }
            }
        }

        .donate-button {
            padding: 8px 15px;
            color: #fff;
            background-color: #fd4c24;
            font-size: 28px;
            text-transform: uppercase;
            display: block;
            min-width: 250px;
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            position: relative;
            &:before {
                content: "";
                position: absolute;
                border: #fd4c24 solid 4px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                transition-duration: 0.3s;
                transition-property: top, right, bottom, left;
            }
            &:hover {
                &:before {
                    top: -8px;
                    right: -8px;
                    bottom: -8px;
                    left: -8px;
                }
            }
        }

        .map {
            height: 200px;
            position: relative;

            img {
                max-width: 100%;
                max-height: 100%;
                border: 4px solid rgba(255, 255, 255, 0.2);
            }

            .marker {
                display: block;
                background-image: url("/images/marker-standard.png");
                background-size: contain;
                width: 33px;
                height: 46px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -40px;
                margin-left: -12.5px;
                z-index: 2;
            }
        }
    }
}

.ProfileContent {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    > section {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: #fff;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        padding: 2em;
        font-size: 16px;
    }

    > section.split {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        overflow: hidden;
        padding: 0;

        .content {
            width: 100%;
            height: 100%;
            overflow: auto;
            padding: 2em;
        }
    }

    h1.heading {
        border-bottom: 1px solid #ddd;
        margin-top: 1.5em;

        &:first-child {
            margin-top: 0;
        }
    }
}

.ProfileContainer nav {
    display: block;
    background-color: @profile-nav-color;
    border-top: 1px solid darken(@profile-nav-color, 15%);
    border-bottom: 1px solid darken(@profile-nav-color, 15%);

    .nav {
        li {
            display: inline-block;
            vertical-align: top;
            border-right: 1px solid darken(@profile-nav-color, 15%);

            &:first-child {
                border-left: 1px solid darken(@profile-nav-color, 15%);
            }

            a {
                color: #fff;
                padding: 0.75em 1.5em;
                overflow: hidden;

                &:hover {
                    background-color: lighten(@profile-nav-color, 15%);
                }

                > * {
                    vertical-align: middle;
                }

                .fa {
                    font-size: 2em;
                    margin-right: 0.5em;
                }
            }

            &:after {
                background-color: darken(@profile-nav-color, 15%);
                visibility: hidden;
                position: absolute;
                content: "";
                top: 100%;
                left: 50%;
                transform: translateX(-50%) translateY(-150%) rotate(45deg);
                transition: opacity 250ms ease-in, transform 250ms ease-in;
                margin: 0.5px 0 0;
                width: 0.8em;
                height: 0.8em;
                border: none;
                border-bottom: 1px solid darken(@profile-nav-color, 15%);
                border-right: 1px solid darken(@profile-nav-color, 15%);
                z-index: 2;
            }
        }

        li.active {
            a,
            a:active {
                background-color: darken(@profile-nav-color, 15%);
            }

            &:after {
                visibility: visible;
                transform: translateX(-50%) translateY(-55%) rotate(45deg);
            }

            .fa {
                animation: 750ms bounce-icon;
                animation-timing-function: ease-in-out;
            }
        }
    }
}

.InfoBox {
    min-width: 250px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: stretch;
    background-color: @gray-lighter;

    .InfoBoxItem {
        text-align: center;
        color: #222;

        .fa {
            display: block;
            color: @brand-danger; //rgba(0,0,0, 0.15);
            font-size: 2em;
        }

        h5 {
            text-transform: uppercase;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        p {
            margin: 0;
            font-size: 18px;
        }
    }
}

#programs .Program,
#victories .Victory {
    border-left: 4px solid @brand-danger;
    background-color: #f3f3f3;
    padding: 1.5em;
    margin-bottom: 2em;

    &:last-child {
        margin-bottom: 0;
    }

    ul {
        list-style: none;
        display: inline-block;
        vertical-align: top;
        padding: 0;
        margin: 0;

        li {
            padding: 0.25em 0;
            display: flex;
            align-items: baseline;
        }

        li.heading {
            text-transform: uppercase;
            font-weight: 500;
        }

        .placeholder {
            color: #bbb;
        }

        .fa {
            color: @brand-danger;
            margin-right: 1em;
        }
    }
}

#programs .Program {
    .description {
        margin: 2em 0;
    }

    ul {
        width: ~"calc(100% / 3)";
        padding-right: 0.5em;
    }
}

#victories .Victory {
    overflow: auto;

    > .heading {
        margin-bottom: 1em;
    }

    > .scope {
        text-transform: uppercase;
    }

    > .description {
        margin: 1.5em 0 0;
    }

    > ul {
        width: 250px;
        float: right;
        background-color: #fff;
        padding: 0.5em 1em;
        margin: 0 0.5em 1em 1em;
    }
}

.ProfileContent #contact {
    text-align: center;
    padding: 2em;

    hr {
        margin: 3em 0;
    }

    .title {
        text-transform: uppercase;
        font-weight: normal;
    }

    .social {
        word-spacing: 2em;

        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            height: 80px;
            color: #fff;
            margin-right: 20px;

            &:last-of-type {
                margin: 0;
            }

            &.twitter {
                background-color: #55acee;
            }
            &.facebook {
                background-color: #3b5998;
            }
            &.youtube {
                background-color: #cd201f;
            }
            &.instagram {
                background-color: #000;
            }

            .fa {
                font-size: 3em;
            }
        }
    }

    address {
        p {
            margin: 0;
            padding: 0;
        }
    }
}

.navigate-profile-enter {
    z-index: 1;
}

.navigate-profile-leave {
    transform: translateY(0);
    transition: transform 750ms ease-in-out;
    z-index: 2;
}
.navigate-profile-leave.navigate-profile-leave-active {
    transform: translateY(100%);
}

@keyframes bounce-icon {
    0% {
        transform: translateY(0);
    }
    49% {
        transform: translateY(50px);
        opacity: 1;
    }
    50% {
        opacity: 0;
        transform: translateY(-50px);
    }
    51% {
        opacity: 1;
    }
    100% {
        transform: translateY(0);
    }
}

.OrganizationProfile.public-false .name h1,
.OrganizationProfile.public-false .content div,
.OrganizationProfile.public-false .content ul li,
.OrganizationProfile.public-false .ProfileContent #contact .contact h2,
.OrganizationProfile.public-false .ProfileContent #contact .contact h4,
.OrganizationProfile.public-false .ProfileContent #contact .contact a,
.OrganizationProfile.public-false .InfoBox .InfoBoxItem p {
    color: transparent;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
