.View {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0,0,0, 0.4);
        z-index: 1;
    }

    .leaflet-container {
        width: 100%;
        height: 100%;
    }
}

.ProfileView {
    position: fixed;
    top: @header-height;
    z-index: 1001;
}
